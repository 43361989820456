import classNames from "classnames"
import { graphql } from "gatsby"
import React from "react"

import BrandPreview from "../components/generic-preview/brand-preview/brand-preview"
import OverviewGrid from "../components/overview-grid/overview-grid"
import OverviewSlider from "../components/overview-slider/overview-slider"
import PageHeader from "../components/page-header/page-header"
import FileDownloads from "../components/resources/file-downloads/file-downloads"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import useBrands from "../hooks/useBrands"
import useDownloads from "../hooks/useDownloads"
import { useTranslation } from "../i18n/translate"
import { MarketModel } from "../model/marketModel"

const MarketPage = ({ pageContext, data, location }) => {
  const { t } = useTranslation()

  let { market } = pageContext

  market = new MarketModel(market)

  const downloads = useDownloads().filter(
    (download) => download.markets.findIndex((m) => m.id === market.id) !== -1,
  )

  const featuredBrands = useBrands().filter((brand) =>
    pageContext.featuredBrands.includes(brand.url),
  )

  // Set variable if market has download resources
  const hasResources = downloads.length > 0

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t(market.name.key)}
        descriptionTranslatedString={t(market.description?.key)}
      />

      <PageHeader
        levelThreeTitle={t(market.name.key)}
        levelTwoTitle={t(market.name.key)}
        levelOneTitle={t("MARKETS-META_TITLE")}
      />

      <div className="_fp-global-container _fp-grid--margin-top">
        <div className="_fp-grid _fp-grid--gap">
          {/* Make tile full-width if market has no resources (resource tile is hidden) */}
          {market.description?.key && (
            <>
              <div
                className={classNames(
                  hasResources ? "_fp-col-7" : "_fp-col-12",
                  "_fp-col-12p",
                  "_fp-col-12sp",
                )}
              >
                {market.description?.key && (
                  <Tile
                    titleTranslatedString={t("MARKETS-MARKET_DESCRIPTION")}
                    linkPath={market.getProductPageUrl()}
                    linkTextTranslatedString={t("PRODUCTS-VIEW_ALL_PRODUCTS")}
                  >
                    <p className={"_fp-text _fp-text--columns"}>
                      {t(market.description?.key)}
                    </p>
                    <TileContentSpacer size={"small"} />
                  </Tile>
                )}
              </div>
              {/* Hide resources tile if market has no resources */}
              {hasResources && (
                <div className="_fp-col-5 _fp-col-12p _fp-col-12sp">
                  <Tile titleTranslatedString={t("GLOBAL-PRODUCT_BROCHURES")}>
                    <FileDownloads downloads={downloads} fileStyle={"gray"} />
                  </Tile>
                </div>
              )}
            </>
          )}
          {featuredBrands.length > 0 && (
            <div className="_fp-col-12">
              <Tile
                autoHeight={true}
                noTile
                titleTranslatedString={t("BRANDS-BRANDS")}
              >
                <OverviewGrid>
                  {featuredBrands
                    .filter((brand) => !brand.hidden)
                    .sort((a, b) => {
                      return t(a.name.key).localeCompare(t(b.name.key))
                    })
                    .map((brand) => {
                      return (
                        <BrandPreview
                          market={market}
                          brand={brand}
                          key={`key_${brand.id}`}
                        />
                      )
                    })}
                </OverviewGrid>
              </Tile>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MarketPage

export const query = graphql`
  query ($marketUrl: String) {
    strapiMarket(url: { eq: $marketUrl }) {
      cover_image {
        localFile {
          childImageSharp {
            desktop: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
